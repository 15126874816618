import React,{ Component, Fragment } from "react";
import { connect } from 'react-redux';
import { socketConnect } from 'socket.io-react';
import { getArrays } from 'lib';
import PropTypes from 'prop-types';
import moment from 'moment';
import { setCheckoutPlanParams,updateCheckoutPlanParams } from '../../store/checkoutPlanParams';
import { setCheckoutMealPlan,updateCheckoutMealPlan } from '../../store/checkoutMealPlan';
import { spin, stop } from '../../store/spinner'; 
import Tabs from '../../components/Checkout/Tabs';
import BottomNav from '../../components/Checkout/BottomNav';
import Select from 'components/Select';
import { browserHistory } from "react-router";
import CheckIcon from 'assets/checkDiet.png';

class CheckoutStep2 extends Component{

    constructor(props) {
        super(props);
        const { checkoutPlanParams,dataArray } = this.props;
        const { mealTypes:prevMealType, days_count, mealPlan_id:mealPlan,diet_id,exclude_week_day,id:checkoutId} = checkoutPlanParams;
        if(checkoutId) browserHistory.push('/checkout/menus');
        if(!diet_id) browserHistory.push('/checkout');
        const { mealtypeMobCombinations } = dataArray;
        let comb= (prevMealType && mealtypeMobCombinations && mealtypeMobCombinations[prevMealType.join('-')]!=undefined) ?mealtypeMobCombinations[prevMealType.join('-')]:false;
        let rangeId = this.getDayRangeId(days_count);
        this.state={
            mealTypeList:[],
            mealType:prevMealType|| [],
            dayCount:days_count || 20,
            selectedDay:(days_count>0 && ![5,6,20,24,30,40,60].includes(parseInt(days_count)))?{label:days_count+' Days',value:days_count}:[],
            excluded:exclude_week_day || [],
            mealplanId:mealPlan || null,
            dayRangeId:rangeId || null,
            mealTypeCom:comb || null
        }
    }
    listeners = action => {
        const { message } = action.data || {};
        switch (action.type) {
           default:

           break;
        }
    }
    componentDidMount(){
        const { socket } = this.props;
        if(socket.connected){
            this.initializeData();
        }else{
            socket.on('connect', (data) => {
                this.initializeData();
            });
        } 
    }
    initializeData =() =>{
      const { socket, dataArray,user, checkoutPlanParams } = this.props;
      const types = ['dietMobileList','typeList','planDayRange','priceList','mealtypeMobCombinations','daysRangeMob','dietMealTypeDiscounts','commonVouchers','emirate','emirateSlots','sidesTypes', 'typeList', 'dietListFull'].filter(el => !(el in dataArray));
      types.length && getArrays(socket, types);
      const { dietMobileList } =dataArray;
      //this.getPending(user.user_id);
      const { diet_id,days_count,mealTypes } = checkoutPlanParams;
      if(!diet_id) browserHistory.push('/checkout');
      let mealTypeList =dietMobileList && dietMobileList.filter((value)=>(value.id ==diet_id  && value.mealtypes)); 
      if(mealTypeList && mealTypeList[0]!=undefined){
          this.setState({'mealTypeList':mealTypeList[0].mealtypes,mealType:(mealTypes!=undefined && mealTypes.length>0)?mealTypes:mealTypeList[0].mealtypes.map(Number).sort()},() => {this.updateMealPlanId()});
      }
      if(days_count!=undefined){
        this.updateMealPlanId();
      }
    }
    componentWillMount() {
        this.props.socket.on('mobile_checkout', this.listener);
    }
    componentWillUnmount() {
      this.props.socket.removeListener('mobile_checkout', this.listener);
    }
    // componentWillReceiveProps(next) {
    //     const { userData: nextUserData } = next;
    //     const { userData: curUserData, userData } = this.props;
    // }
    updateMealPlanId = ()=>{
        const { dataArray } = this.props;
        const { dayCount, mealType } = this.state;
        const { mealtypeMobCombinations, typeList } = dataArray;
        console.log('mealtypeMobCombinations');
        console.log(mealtypeMobCombinations);
        const childTypes= mealType.map((val)=>{
          const { children } = typeList[+val];
          return (children!=null)?children.id:null;
      }).filter((el)=>{ return el != null; });
      let mealTypeHandle= mealType.filter((el)=>{ return !childTypes.includes(el);}).sort(function(a, b) {
        return a - b;
      }).join('-');
        let comb= (mealtypeMobCombinations && mealtypeMobCombinations[mealTypeHandle]!=undefined) ?mealtypeMobCombinations[mealTypeHandle]:false;
        let mealP= this.getMealPlanId(dayCount);
        let rangeId = this.getDayRangeId(dayCount);
        this.setState({dayRangeId:rangeId,mealplanId:mealP,mealTypeCom:comb})
    }
    calcPrice = (types) => {
        let pricetype=0;
        const {  dataArray,checkoutPlanParams } = this.props;
        const { diet_id: diet} = checkoutPlanParams;
        const { priceList } = dataArray;
        const { dayCount, mealplanId} = this.state;
        types=types.map(Number);
        console.log('mealType');
        console.log(types);
        if (priceList && diet && mealplanId && types ) {
          const dayPrice = priceList
            .filter(el => +el.diet_id === +diet && +el.mealPlan_id === +mealplanId && types.includes(+el.mealType_id))
            .reduce((acc, cur) =>{ if(pricetype){ return acc + +cur.newValue }else{ return acc + +cur.value } }, 0);
          return dayPrice * +dayCount;
        } else{
          return 0;
        }
      }
      getPriceData = () =>{ 
        let priceDatas = {};
        const {  dataArray,checkoutPlanParams } = this.props;
        const { diet_id: diet} = checkoutPlanParams;
        const { dietMealTypeDiscounts, commonVouchers } = dataArray;
        const { dayRangeId, mealTypeCom, mealType } = this.state;
        const clearPrice = this.calcPrice(mealType);
        let combo = (dietMealTypeDiscounts!=undefined && dietMealTypeDiscounts[+diet]!=undefined && dietMealTypeDiscounts[+diet][mealTypeCom]!=undefined && dietMealTypeDiscounts[+diet][mealTypeCom][dayRangeId]!=undefined)?dietMealTypeDiscounts[+diet][mealTypeCom][dayRangeId]:'';
        let discount = 0;
        let discountPer = 0;
        let discountType = 0;
        const vou = commonVouchers && commonVouchers.find(({ id }) => +id === +combo)
        if(combo && vou != undefined){
            let isMealTypeDis =0;
            // const vou = commonVouchers[combo];
            /* Apply the discount based meal types */
            if(vou && vou.conditions!=null && vou.conditions!='' ){
              vou.conditions =(typeof vou.conditions === 'string' || vou.conditions instanceof String )?JSON.parse(vou.conditions):vou.conditions;
              isMealTypeDis=intersect(mealType,vou.conditions).length;   
            }
            discount = (vou && vou.conditions!=null && vou.conditions.length>0 && isMealTypeDis)?calcPrice(vou.conditions):(vou ? (vou.type === 'fixed' ? +vou.value : (vou.type === 'percent' ? (+vou.value / 100) * clearPrice : 0)) : 0);
            discountPer = +vou.value;
            discountType = vou.type;
        }
        priceDatas.price = clearPrice;
        priceDatas.discountPrice = clearPrice - discount;
        priceDatas.savedAmount = discount;
        priceDatas.discountPer = discountPer;
        priceDatas.discountType = discountType;
        priceDatas.voucher_field = 'voucher_id';
        priceDatas.voucher_id = (vou || {}).id;
        return priceDatas;
    }
    getDayRangeId = (count) =>{
        const { dataArray } = this.props;
        const { daysRangeMob } = dataArray;
        count=parseInt(count);
        let rangeId=0;
        daysRangeMob && Object.keys(daysRangeMob).forEach((mealplanId, index) => {
              const { min:minVal, max:maxVal } = daysRangeMob[mealplanId] || {};
              if(parseInt(minVal)<=count && parseInt(maxVal)>=count){
                rangeId=mealplanId;
                return mealplanId;
              }
        });
        return parseInt(rangeId);
    }
    getMealPlanId= (count) =>{
        const { dataArray } = this.props;
        const { planDayRange } = dataArray;
        count=parseInt(count);
        let planId=0;
        planDayRange && Object.keys(planDayRange).forEach((mealplanId, index) => {
              const { range_from, range_to } = planDayRange[mealplanId] || {};
              if(parseInt(range_from)<=count && parseInt(range_to)>=count){
                planId=mealplanId;
                return mealplanId;
              }
        });
        return parseInt(planId);
      }
    _addMealTypes = (item) =>{
      const { dataArray,checkoutPlanParams }= this.props;
      const { diet_id:diet } = checkoutPlanParams;
      const { typeList,dietListFull } = dataArray;
        const { mealType } = this.state;
        const { children } = typeList[+item];
        let selected = [];
        if (mealType.includes(+item)) {
          selected = mealType.filter(
            (meal) => meal !== +item
          );
          if(children && dietListFull[diet] && dietListFull[diet].sides_id!=null){
            selected = selected.filter(el => el != +children.id);
          }
        }else{
          selected =[...mealType,+item];
          if(children && dietListFull[diet] && dietListFull[diet].sides_id!=null){
            selected = [...selected, (+children.id)];
          }
        }
        selected.sort(); 
        this.setState({'mealType':selected},() => {this.updateMealPlanId()});
    }
    renderMealTypes(){
        const { dataArray } = this.props;
        const { mealTypeList, mealType } = this.state;
        const { typeList } = dataArray;
        const list= mealTypeList && mealTypeList.map((item,index)=>{
                return <li className={`diet-list meal-type-l ${(mealType.includes(+item)) ? 'diet-active' : ''}`} onClick={() => this._addMealTypes(+item)}>
                    <span>{typeList[item].title}</span>
                    {(mealType.includes(+item)) && <img src={CheckIcon} className="checkIcon" />}
                </li>
        });
        return <div className="step-form-input">
            <label>Choose your meals</label>
            <ul className="checkout-diet-list">
            {list}
        </ul></div>;
    }
    _addExcludedWeek = (item) =>{
        item = String(item);
        const { excluded } =this.state;
        if (excluded.includes(item)) {
          this.setState({excluded:excluded.filter(
            (meal) => meal != item
          )});
        }else{
            this.setState({excluded:[...excluded,item]});
        }
      }
      continuePress = ()=>{
        const { dataArray, updateCheckoutPlanParams } = this.props;
        const { mealtypeMobCombinations,typeList } = dataArray;
        const { mealType,mealplanId,dayCount,excluded } = this.state;
        let isValid = true;
        if(mealType.length>0){
          const childTypes= mealType.map((val)=>{
            const { children } = typeList[+val];
            return (children!=null)?children.id:null;
        }).filter((el)=>{ return el != null; });
        // console.log(mealType);
        // console.log(childTypes);
          let name='mealTypes';
          let mealTypeHandle= mealType.filter((el)=>{ return !childTypes.includes(el);}).sort(function(a, b) {
            return a - b;
          }).join('-');
          console.debug('mealTypeHandle');
          console.debug(mealTypeHandle);
          console.debug(mealtypeMobCombinations);
          console.debug(mealType.sort());
          isValid = (mealtypeMobCombinations && mealtypeMobCombinations[mealTypeHandle]!=undefined) ?mealtypeMobCombinations[mealTypeHandle]:false;
          if(isValid){
            updateCheckoutPlanParams({ [name]: mealType, modified: true });
          }else{
            alert(
              'Minimum 1 Main Meal required. Please select at least 1 main meal or some other combination.'
            )
          }
        }else{
          isValid =false;
         alert(
            'Please select mealType.'
          )
        } 
        if(dayCount){
          let name='days_count';
          updateCheckoutPlanParams({ [name]: dayCount, mealPlan_id: mealplanId});
        }else{
          isValid =false;
          alert(
            'Please select day.')
        }
       
        if(isValid){
            const priceDatas = this.getPriceData();
           updateCheckoutPlanParams({ price:priceDatas.discountPrice,voucher_field:'voucher_id',voucher_id:priceDatas.voucher_id,priceDatas,exclude_week_day:excluded});
        }
        return isValid;
      }
    renderDays(){
        const { dayCount, selectedDay, excluded } =this.state;
        let mainDays =[ 
           { title:'5 Days',value:5},
           { title:'6 Days',value:6},
           { title:'20 Days',value:20},
           { title:'24 Days',value:24},
           { title:'30 Days',value:30},
           { title:'40 Days',value:40},
           { title:'60 Days',value:60}
        ];
        const skipdays = [ {'text':'Monday','index':1}, {'text':'Tuesday','index':2}, {'text':'Wednesday','index':3}, {'text':'Thursday','index':4}, {'text':'Friday','index':5}, {'text':'Saturday','index':6},{'text':'Sunday','index':0}];
        const daysList =[];
        for(var i=7;i<=90;i++){
            if(![5,6,20,24,30,40,60].includes(parseInt(i))){
                daysList.push({label: i+' days', value: parseInt(i)});
            }
        }
        const radioList =mainDays.map((value)=>{
            return <li><input type="radio" value={value.value} name="days" id={'label-'+value.value} checked={value.value==dayCount} onChange={(e)=>{this.setState({dayCount:parseInt(e.target.value),selectedDay:[]},() => {this.updateMealPlanId()})}} /> <label for={'label-'+value.value}>{value.title}</label></li>;
        });
        const checkBoxList =skipdays.map((value)=>{
            return <li><input type="checkbox" value={value.index} name="skipdays" id={'labelskipdays-'+value.index} checked={(excluded.includes(String(value.index)))} onChange={(e)=>{this._addExcludedWeek(e.target.value)}} /> <label for={'labelskipdays-'+value.index}>{value.text}</label></li>;
        });
     return <div className="delivery-date">
        <div className="step-form-input">
            <label>Choose your delivery days</label>
            <ul className="radio-list">
            {radioList}
            </ul>
        </div>
        <div className="popup-select-area step-form-input">
        <label className="food-prefs-header">Other Days</label>
        <div className={'className'}>
        <Select
             multiple={false}
             isgrouped={true}
          placeholder='Select Days'
          list={daysList}
          value={selectedDay || []}
          isSearchable={true}
          name='selectedDay'
          onChange={ (name, val) => {
            // console.log(val);
           // this.changeDislike('dislikes', val);
           this.setState({
           'dayCount': parseInt(val.value),
           'selectedDay': val
          },() => {this.updateMealPlanId()});
          }}
        />
      </div>
      </div>
      <div className="step-form-input">
      <label>Select Days to Skip</label>
        <ul className="checkbox-list">
        {checkBoxList}
        </ul>
     </div></div>;

    }
    
    render() {
        const priceDatas = this.getPriceData();
       console.log(priceDatas);
        return <Fragment>
            <div className="main-holder checkout-process-con wrap-progress-page" >
            <div className="container checkout-process">
                        <div className="row">
                        <div className='col-12'>
                           <Tabs />
                           <div className="inner-content">
                            <h2 className="mobile-header">Meals</h2>
                            {this.renderMealTypes()}
                            {this.renderDays()}
                           </div>
                           <BottomNav priceDatas={priceDatas} isShowBag={true} continueValid={this.continuePress} />
                        </div>
                    </div>
                    </div>
              </div>
        </Fragment>
    }
}
CheckoutStep2.propTypes = {
    checkoutPlanParams: PropTypes.object.isRequired,
    checkoutMealPlan: PropTypes.object.isRequired,
    setCheckoutMealPlan: PropTypes.func.isRequired,
    setCheckoutPlanParams: PropTypes.func.isRequired,
    dataArray: PropTypes.object.isRequired
};
  
const mapStateToProps = state => ({
    checkoutPlanParams: state.checkoutPlanParams,
    dataArray: state.dataArray,
    user:state.user,
    checkoutMealPlan: state.checkoutMealPlan
});

const bindAction = dispatch => ({
    setModal: obj => dispatch(setModal(obj)),
    setCheckoutMealPlan: plan => dispatch(setCheckoutMealPlan(plan)),
    setCheckoutPlanParams: obj => dispatch(setCheckoutPlanParams(obj)),
    updateCheckoutPlanParams: obj => dispatch(updateCheckoutPlanParams(obj)),
    spin: () => dispatch(spin()),
    stop: () => dispatch(stop()),
    updateCheckoutMealPlan: (date,obj) => dispatch(updateCheckoutMealPlan(date,obj)),
    setModal: obj => dispatch(setModal(obj))
});
  
export default socketConnect(connect(mapStateToProps, bindAction)(CheckoutStep2));